import React from 'react'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'
import Button, { type ButtonProps } from '~/components/Button'
import Container from '~/components/Container'
import DownloadAppClient from '~/components/DownloadAppClient'
import InstagramIcon from '~/components/icons/Instagram'
import LinkedinIcon from '~/components/icons/Linkedin'
import Logo from '~/components/icons/Logo'
import LanguageSwitcher from '~/components/LanguageSwitcher'
import Link, { type LinkProps } from '~/components/Link'
import LinkButton from '~/components/LinkButton'
import Typography from '~/components/Typography'
import { CHECK_UP_LOCATION } from '~/constants/offer'
import { ROUTES } from '~/constants/routes'
import { INSTAGRAM_URL, LINKEDIN_URL } from '~/constants/social'
import { cn } from '~/utils/cn'
import { trackEvent } from '~/utils/tracking'

type CategoryLinks = {
  category: string
  items: ({
    label: string
  } & LinkProps)[]
}

const getCategoriesLinks = (t: TFunction): CategoryLinks[] => {
  return [
    {
      category: t('common:discover'),
      items: [
        {
          label: t('common:theCheckUp'),
          to: ROUTES.checkup
        },
        {
          label: t('common:theFollowUp'),
          to: ROUTES.followup
        },
        {
          label: t('common:forCompanies'),
          to: ROUTES.companies
        }
      ]
    },
    {
      category: t('common:book'),
      items: [
        {
          label: t('common:forMe'),
          to: ROUTES.subscription_booking
        },
        {
          label: t('common:throughCompany'),
          to: ROUTES.bookingCompanies_form
        }
      ]
    },
    {
      category: t('common:footer_questions'),
      items: [
        {
          label: t('common:contact'),
          to: ROUTES.contactUs
        },
        {
          label: t('common:q&a'),
          to: ROUTES.faq
        }
      ]
    },
    {
      category: 'Zoī',
      items: [
        {
          label: t('common:theTeam'),
          to: ROUTES.team
        },
        {
          label: t('common:manifesto'),
          to: ROUTES.manifesto
        },
        {
          label: t('common:jobs'),
          to: ROUTES.jobs
        },
        {
          label: t('common:research'),
          to: ROUTES.research
        }
      ]
    }
  ]
}

const getFooterLinks = (t: TFunction) => {
  return [
    {
      label: t('common:privacyNotice'),
      to: ROUTES.privacyNotice
    },
    {
      label: t('common:cookies'),
      to: ROUTES.cookiesNotice
    },
    {
      label: t('common:footer_manageCookies'),
      to: '#',
      onClick: (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()

        if (typeof window.openAxeptioCookies === 'function') {
          window.openAxeptioCookies()
        }
      }
    },
    {
      label: t('common:legalMentions'),
      to: ROUTES.legalMentions
    },
    {
      prefix: '© Photos : ',
      label: '11h45',
      to: 'https://www.11h45.com',
      target: '_blank',
      rel: 'noreferrer',
      underline: 'always' as const,
      onClick: () => {
        trackEvent('PHOTO_CREDIT_CLICK')
      }
    }
  ]
}

const Delimiter = ({ className }: PropsWithClassName) => {
  return <div className={cn(className, 'h-[1px] w-full bg-white/10')} />
}

const Footer = () => {
  const { t } = useTranslation()

  const categoriesLinks = getCategoriesLinks(t)
  const footerLinks = getFooterLinks(t)

  return (
    <footer className="border-t-1 border-white/10 bg-black font-body text-white">
      <div className="flex flex-col lg:gap-y-16">
        <Container className="max-md:max-w-none max-md:px-0">
          <div className="flex justify-between pt-10 max-md:flex-col lg:pt-16">
            <div className="flex flex-col items-start gap-y-8 container-gutters-x max-lg:pb-10 md:gap-y-6 md:px-0">
              <Link to="/" prefetch="none" aria-label="Lien vers le site Zoī">
                <Logo className="h-auto w-[96px] lg:w-[80px]" />
              </Link>
              <Typography
                variant="h4"
                component="p"
                family="brand"
                className="max-md:text-balance md:max-w-sm"
              >
                {t('common:caption')}
              </Typography>
              <DownloadAppClient
                render={({ appUrl, openModal }) => {
                  const buttonProps = {
                    className: 'max-sm:w-full',
                    color: 'secondary',
                    rounded: 'full',
                    children: t('common:downloadApp'),
                    onClick: () => {
                      trackEvent('DOWNLOAD_APP_LINK_CLICK')
                    }
                  } satisfies ButtonProps

                  return !appUrl ? (
                    <Button {...buttonProps} onClick={openModal} />
                  ) : (
                    <LinkButton to={appUrl} {...buttonProps} />
                  )
                }}
              />
            </div>
            <Delimiter className="md:hidden" />
            <div className="flex flex-col max-md:items-center md:gap-y-6">
              <address className="w-full max-md:text-center">
                <Link
                  rel="noreferrer"
                  className="block w-full container-gutters-x max-md:py-6 md:px-0"
                  to={CHECK_UP_LOCATION.link}
                  underline="hover"
                  target="_blank"
                >
                  {CHECK_UP_LOCATION.address}
                </Link>
              </address>
              <Delimiter className="md:hidden" />
              <div className="flex w-full gap-x-6 container-gutters-x max-md:items-center max-md:justify-center max-md:py-6 md:flex-row-reverse md:px-0">
                <Link
                  to={LINKEDIN_URL}
                  target="_blank"
                  rel="noreferrer"
                  underline="hover"
                  aria-label="Page LinkedIn Zoī"
                  className="flex items-center justify-center max-md:aspect-square max-md:w-[44px] max-md:rounded-full max-md:border max-md:border-white/25"
                  onClick={() => {
                    trackEvent('FOOTER_LINKEDIN_CLICK')
                  }}
                >
                  <LinkedinIcon
                    width={20}
                    height={20}
                    className="max-md:h-auto max-md:w-[16px]"
                  />
                </Link>
                <Link
                  to={INSTAGRAM_URL}
                  target="_blank"
                  rel="noreferrer"
                  underline="hover"
                  aria-label="Compte Instagram Zoī"
                  className="flex items-center justify-center max-md:aspect-square max-md:w-[44px] max-md:rounded-full max-md:border max-md:border-white/25"
                  onClick={() => {
                    trackEvent('FOOTER_INSTAGRAM_CLICK')
                  }}
                >
                  <InstagramIcon
                    width={20}
                    height={20}
                    className="max-md:h-auto max-md:w-[16px]"
                  />
                </Link>
              </div>
            </div>
          </div>
        </Container>
        <Delimiter />
        <Container>
          <div className="flex gap-x-4 max-lg:flex-col">
            {categoriesLinks.map((categoryLinks) => {
              return (
                <div
                  className="flex flex-1 flex-col gap-y-4 max-lg:py-4"
                  key={categoryLinks.category}
                >
                  <Typography
                    variant="body2"
                    component="p"
                    className="text-white/60"
                  >
                    {categoryLinks.category}
                  </Typography>
                  <ul className="flex flex-col gap-y-2">
                    {categoryLinks.items.map((link) => {
                      return (
                        <li key={link.label}>
                          <Link underline="hover" {...link}>
                            {link.label}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
        </Container>
        <Container className="max-lg:mt-10">
          <div className="flex items-center justify-between gap-4 pb-12 max-lg:flex-col lg:pb-10">
            <div>
              <ul className="flex flex-wrap items-center justify-center gap-x-3 gap-y-2">
                {footerLinks.map((link, index, array) => {
                  const isLastItem = array.at(-1) === link

                  return (
                    <React.Fragment key={link.label}>
                      <li className="text-nowrap text-white/60">
                        {link.prefix}{' '}
                        <Link underline="hover" {...link}>
                          {link.label}
                        </Link>
                      </li>
                      {!isLastItem ? (
                        <li>
                          <span className="block h-[3px] w-[3px] bg-white/60" />
                        </li>
                      ) : null}
                    </React.Fragment>
                  )
                })}
              </ul>
            </div>
            <div className="max-lg:-order-1">
              <LanguageSwitcher />
            </div>
          </div>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
